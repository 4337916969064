import { h } from 'preact';
import { Router } from 'preact-router';

// import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';

const App = () => (
  <div id="app">
    {/* <Header /> */}
    <Router>
      <Home path="/" />
      <Home path="/dig" redirectURI="https://docs-rutgers.lightning.force.com" />
    </Router>
  </div>
);

export default App;
